import React, { Component, useState } from "react";
import {
  Select,
  MenuItem,
  Box,
  Button,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Collapse,
  Typography,
  ButtonGroup,
  Stack,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
} from "recharts";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import RemoveIcon from "@mui/icons-material/Remove";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import clone from "clone";
import Loading from "components/loading";
import NoReport from "../no-report";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { parseProductReport } from "services/helpers/excel";
import { convertCurrency, defaultCurrency } from "services/helpers/exchange";

type ProductReportState = {
  chartType: string;
  startdate: any;
  enddate: any;
  date: number;
  reportData: any;
  lineData: any;
  products: string[];
  selectedProduct: string;
  per: number;
  userBranch: any;
  subBranches: any[];
  selectedBranches: string[];
  branchDialog: boolean;
  loading: boolean;
};

interface ProductReportProps extends WithTranslation {}

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
  process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      checked: string;
      unchecked: string;
    };
    default: {
      primary: string;
      secondary: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      checked?: string;
      unchecked?: string;
    };
    default?: {
      primary?: string;
      secondary?: string;
    };
  }
}

const theme = createTheme({
  status: {
    checked: "##003399",
    unchecked: "#0099CC",
  },
  default: {
    primary: "#717D8C",
    secondary: "#2F343A",
  },
});

const SubbranchButton = styled(Button)(({ theme }) => ({
  color: theme.default.secondary,
  backgroundColor: theme.default.primary,
}));

const BranchButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: theme.default.primary,
  fontSize: "0.9vw",
  padding: "0.5vw",
  height: "8vh",
  width: "10vw",
  "&:hover": {
    color: "#66CCFF",
    backgroundColor: "#003399",
  },
}));

class Product extends Component<ProductReportProps, ProductReportState> {
  constructor(props: any) {
    super(props);
    this.collapse = this.collapse.bind(this);
  }
  state: ProductReportState = {
    chartType: this.props.t("table-chart"),
    startdate: new Date(Date.now() - 86400000),
    enddate: new Date(),
    date: 259200000,
    reportData: [],
    products: [],
    selectedProduct: "",
    per: 86400000,
    userBranch: {},
    subBranches: [],
    selectedBranches: [],
    branchDialog: false,
    lineData: [],
    loading: true,
  };

  async componentDidMount() {
    let products: any = await axios.get("manager/product", {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
    });
    let data: any = await axios.get(`manager/report/product`, {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
      params: {
        startdate: new Date(this.state.startdate).getTime(),
        enddate: new Date(this.state.enddate).getTime(),
      },
    });
    let branch: any = await axios.get(`manager/branches`, {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
    });
    this.setState({
      products: products.data,
      selectedProduct: products.data[0]?.id,
      reportData: data.data,
      userBranch: branch?.data[0],
      subBranches: branch?.data[0]?.subBranch,
      selectedBranches: [branch?.data[0]?._id],
      loading: false,
    });
  }

  exportReport = () => {
    if (this.state.reportData?.data?.length == 0) {
      toast.error(this.props.t("no-ready-report"));
      return;
    }
    const filteredData = [];
    for (const item of this.state.reportData) {
      if (this.state.selectedBranches.includes(item.branchId)) {
        filteredData.push(item);
      }
    }
    parseProductReport(filteredData);
  };

  collapse = (data: any) => {
    const [pageSize, setPageSize] = React.useState(10);
    return (
      <Box>
        <Box
          sx={{
            height: 600,
            width: "100%",
            backgroundColor: "#ededed",
            color: "#000",
            "& .row-class": {
              bgcolor: "#fff",
              color: "#000",
              "&:hover": {
                color: "#000",
              },
              "&:selected": {
                color: "#fff",
              },
            },
          }}
          key={data.branchId}
        >
          <DataGrid
            rows={data.reports?.filter((row: any) => row.saleCount > 0.0) || []}
            columns={this.columns}
            getRowClassName={(params: any) => "row-class"}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
          />
        </Box>
      </Box>
    );
  };
  TableReport = (data: any) => {
    const [expanded, setExpanded] = useState(false);
    return (
      <Box sx={{ marginBottom: "5vw" }}>
        {this.state.subBranches.length > 0 ? (
          <>
            {" "}
            <Typography
              component="div"
              sx={{ cursor: "pointer" }}
              onClick={() => setExpanded(!expanded)}
            >
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#ededed",
                  borderRadius: "10px 10px 0 0",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1.23vw",
                }}
              >
                {data.branchTitle}{" "}
                {expanded ? (
                  <KeyboardArrowUpIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="large" />
                )}
              </Box>
            </Typography>
            <Collapse in={expanded}>{this.collapse(data)}</Collapse>
          </>
        ) : (
          <>{this.collapse(data)}</>
        )}
      </Box>
    );
  };

  PieReport = (data: any, index: number) => {
    const [expanded, setExpanded] = useState(false);
    return (
      <Box sx={{ marginBottom: "5vw" }}>
        <Typography
          component="div"
          sx={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#2F343A",
              borderRadius: "10px 10px 0 0",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "1.23vw",
            }}
          >
            {data.branchTitle}{" "}
            {expanded ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </Box>
        </Typography>
        <Collapse in={expanded}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <h3
                style={{
                  textAlign: "center",
                  margin: "auto",
                  fontWeight: "bold",
                  fontSize: "1.4vw",
                }}
              >
                {this.props.t("sale-count")}
              </h3>
              <PieChart
                width={1200}
                height={700}
                title={this.props.t("sale-count")!}
              >
                <Pie
                  data={data?.reports}
                  labelLine={false}
                  dataKey="saleCount"
                  name={this.props.t("sale-count")!}
                  fill="#8884d8"
                  label={this.renderCustomizedLabel}
                  outerRadius={250}
                >
                  {data?.reports?.map((data: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Box>
            <Box>
              <h3
                style={{
                  textAlign: "center",
                  margin: "auto",
                  fontWeight: "bold",
                  fontSize: "1.4vw",
                  marginTop: 3,
                }}
              >
                {this.props.t("total-price")}
              </h3>
              <PieChart
                width={1200}
                height={800}
                title={this.props.t("total-price")!}
              >
                <Pie
                  data={data?.reports}
                  labelLine={false}
                  dataKey="totalPrice"
                  name={this.props.t("total-price")!}
                  fill="#8884d8"
                  label={this.renderCustomizedLabel}
                  outerRadius={250}
                >
                  {data?.reports?.map((data: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Box>
            <Box>
              <h3
                style={{
                  textAlign: "center",
                  margin: "auto",
                  fontWeight: "bold",
                  fontSize: "1.4vw",
                  marginTop: 3,
                }}
              >
                {this.props.t("average-price")}
              </h3>
              <PieChart
                width={1200}
                height={800}
                title={this.props.t("average-price")!}
              >
                <Pie
                  data={data?.reports}
                  labelLine={false}
                  dataKey="averagePrice"
                  name={this.props.t("average-price")!}
                  fill="#8884d8"
                  label={this.renderCustomizedLabel}
                  outerRadius={250}
                >
                  {data?.reports?.map((data: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Box>
          </Box>
        </Collapse>
      </Box>
    );
  };

  BarReport = (data: any) => {
    const [expanded, setExpanded] = useState(false);
    return (
      <Box sx={{ marginBottom: "5vw" }}>
        <Typography
          component="div"
          sx={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#2F343A",
              borderRadius: "10px 10px 0 0",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "1.23vw",
            }}
          >
            {data.branchTitle}{" "}
            {expanded ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </Box>
        </Typography>
        <Collapse in={expanded}>
          <BarChart
            width={800}
            height={450}
            data={data?.reports}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="product.title" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="saleCount" name="Satış Sayısı" fill="#145F03" />
            <Bar dataKey="totalPrice" name="Toplam Tutar" fill="#03BCD5" />
            <Bar dataKey="averagePrice" name="Ortalama Tutar" fill="#0326D5" />
          </BarChart>
        </Collapse>
      </Box>
    );
  };

  BranchDialog = () => {
    const handleClick = async (value: string) => {
      if (value == "all") {
        if (
          this.state.selectedBranches.length ==
          this.state.subBranches.length + 1
        ) {
          this.setState({
            selectedBranches: [],
          });
          return;
        }
        this.setState({
          selectedBranches: this.state.subBranches
            .map((b: any) => b._id)
            .concat(this.state.userBranch._id),
        });
        return;
      }
      let index: number = this.state.selectedBranches.findIndex(
        (b: any) => b == value
      );
      if (index > -1) {
        let branches: any = clone(this.state.selectedBranches);
        branches.splice(index, 1);
        await this.setState({
          selectedBranches: branches,
        });
        return;
      }
      await this.setState({
        selectedBranches: this.state.selectedBranches.concat([value]),
      });
    };
    return (
      <Dialog
        open={this.state.branchDialog}
        onClose={() => this.setState({ branchDialog: false })}
        fullWidth
        sx={{ overflow: "scroll" }}
      >
        <ThemeProvider theme={theme}>
          <DialogTitle>
            {this.props.t("select-branch-to-list-reports")}
          </DialogTitle>
          <DialogContent>
            <Grid container rowSpacing={1}>
              <Grid item xs={6} key={0}>
                <BranchButton
                  onClick={(e: any) => handleClick(e.target.value)}
                  startIcon={
                    this.state.selectedBranches.length ==
                    this.state.subBranches.length + 1 ? (
                      <DoneAllIcon />
                    ) : (
                      <CloseIcon />
                    )
                  }
                  value="all"
                  sx={
                    this.state.selectedBranches.length ==
                    this.state.subBranches.length + 1
                      ? {
                          color: theme.status.unchecked,
                          backgroundColor: "#003399",
                        }
                      : {}
                  }
                >
                  {this.props.t("all")}
                </BranchButton>
              </Grid>
              <Grid item xs={6} key={1}>
                <BranchButton
                  onClick={(e: any) => handleClick(e.target.value)}
                  startIcon={
                    this.state.selectedBranches.includes(
                      this.state.userBranch._id
                    ) ? (
                      <DoneIcon />
                    ) : (
                      <CloseIcon />
                    )
                  }
                  value={this.state.userBranch._id}
                  sx={
                    this.state.selectedBranches.includes(
                      this.state.userBranch._id
                    )
                      ? {
                          color: theme.status.unchecked,
                          backgroundColor: "#003399",
                        }
                      : {}
                  }
                >
                  {this.state.userBranch.title}
                </BranchButton>
              </Grid>
              {this.state.subBranches.map((branch: any, index: number) => (
                <Grid item xs={6} key={branch._id}>
                  <BranchButton
                    onClick={(e: any) => handleClick(e.target.value)}
                    startIcon={
                      this.state.selectedBranches.includes(branch._id) ? (
                        <DoneIcon />
                      ) : (
                        <CloseIcon />
                      )
                    }
                    value={branch._id}
                    sx={
                      this.state.selectedBranches.includes(branch._id)
                        ? {
                            color: theme.status.unchecked,
                            backgroundColor: "#003399",
                          }
                        : {}
                    }
                  >
                    {branch.title}
                  </BranchButton>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </ThemeProvider>
      </Dialog>
    );
  };
  async syncData() {
    if (this.state.chartType != this.props.t("line-chart")) {
      if (this.state.startdate > this.state.enddate) {
        toast.warning(this.props.t("date-warning"));
        return;
      }
      if (
        !new Date(this.state.enddate)?.getTime() ||
        !new Date(this.state.startdate)?.getTime()
      ) {
        this.setState({
          reportData: [],
        });
        return;
      }
    }
    this.setState({
      loading: true,
    });
    switch (this.state.chartType) {
      case this.props.t("line-chart"):
        let now: any = new Date();
        let reportDate: any = now - this.state.date;
        let data = await axios.get(
          `manager/report/product/${this.state.selectedProduct}`,
          {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
              Authorization: `Bearer ${token}`,
              "x-refresh": refreshToken!,
            },
            params: {
              startdate: reportDate,
              enddate: new Date(),
              per: this.state.per,
            },
          }
        );
        this.setState({
          lineData: data.data,
          loading: false,
        });
        break;
      case this.props.t("pie-chart"):
      case this.props.t("pie-chart"):
      case this.props.t("table-chart"):
        let pieData = await axios.get(`manager/report/product`, {
          baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
          headers: {
            Authorization: `Bearer ${token}`,
            "x-refresh": refreshToken!,
          },
          params: {
            startdate: new Date(this.state.startdate).getTime(),
            enddate: new Date(this.state.enddate).getTime(),
          },
        });
        pieData.data.map((report: any) => {
          report.reports = report.reports.filter((r: any) => r.saleCount != 0);
        });

        this.setState({
          reportData: pieData.data,
          loading: false,
        });
        break;
      default:
        break;
    }
  }
  renderCustomizedLabel = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
      value,
    } = props;
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#015A09"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}{" "}
        {props?.product?.title ? props?.product?.title : props?.product}
      </text>
    );
  };

  columns: GridColDef[] = [
    {
      field: "product",
      headerName: this.props.t("product-name")!,
      width: 270,
      renderCell: (params) => (
        <strong>
          {params.value?.title ? params.value?.title : params.value}{" "}
          {params.value?.sale_type == 2 ? "(Kilogram)" : ""}
        </strong>
      ),
    },
    {
      field: "saleCount",
      headerName: this.props.t("sale-count")!,
      width: 160,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "totalPrice",
      headerName: this.props.t("total-price")!,
      width: 200,
      renderCell: (params) => (
        <>
          {params.value} {defaultCurrency()}
        </>
      ),
    },
    {
      field: "averagePrice",
      headerName: this.props.t("average-price")!,
      width: 200,
      renderCell: (params) => (
        <>
          {params.value} {defaultCurrency()}
        </>
      ),
    },
    {
      field: "status2",
      headerName: this.props.t("daily-average-sale")!,
      width: 200,
      renderCell: (params) => <>{params.row?.status?.averageSale}</>,
    },
    {
      field: "status",
      headerName: this.props.t("status")!,
      width: 150,
      renderCell: (params) => (
        <>
          {params.value?.status === true ? (
            <>
              {params.value?.percent >= 100 ? (
                <KeyboardDoubleArrowUpIcon color="success" />
              ) : (
                <KeyboardArrowUpIcon color="success" />
              )}{" "}
              {params.value?.percent?.toFixed()}%
            </>
          ) : params.value?.status == false ? (
            <>
              {params.value?.percent >= 100 ? (
                <KeyboardDoubleArrowDownIcon color="error" />
              ) : (
                <KeyboardArrowDownIcon color="error" />
              )}{" "}
              {params.value?.percent ? params.value?.percent?.toFixed() : 0}%
            </>
          ) : (
            <>
              <RemoveIcon color="warning" /> 0%
            </>
          )}
        </>
      ),
    },
  ];
  render() {
    if (this.state.loading) {
      return (
        <>
          <Loading />
        </>
      );
    }
    return (
      <Box sx={{ overflow: "auto" }}>
        <this.BranchDialog />
        <Box>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ marginTop: "2vw" }}
          >
            <Box
              sx={{
                marginLeft: "0.5vw",
              }}
            >
              <InputLabel id="chartTypeLabel">
                {this.props.t("chart-type")}
              </InputLabel>
              <Select
                value={this.state.chartType}
                onChange={async (e: any) => {
                  await this.setState({
                    chartType: e.target.value,
                  });
                  this.syncData();
                }}
                labelId="chartTypeLabel"
                sx={{
                  minWidth: "9vw",
                  textAlign: "center",
                }}
              >
                {[
                  this.props.t("table-chart"),
                  this.props.t("line-chart"),
                  this.props.t("pie-chart"),
                  this.props.t("bar-chart"),
                ].map((type: string) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {this.state.chartType == this.props.t("line-chart") ? (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ marginRight: "1.3vw" }}>
                  <InputLabel id="productNameLabel">
                    {this.props.t("product")}
                  </InputLabel>
                  <Select
                    labelId="productNameLabel"
                    value={this.state.selectedProduct}
                    onChange={async (e: any) => {
                      await this.setState({ selectedProduct: e.target.value });
                      this.syncData();
                    }}
                    sx={{ minWidth: "8vw", textAlign: "center" }}
                  >
                    {this.state.products.map((p: any) => (
                      <MenuItem value={p.id} key={p.id}>
                        {p.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ marginRight: "1.3vw" }}>
                  <InputLabel id="dateLabel">
                    {this.props.t("sinceThatTime")}
                  </InputLabel>
                  <Select
                    labelId="dateLabel"
                    value={this.state.date}
                    onChange={async (e: any) => {
                      await this.setState({ date: e.target.value });
                      this.syncData();
                    }}
                    sx={{ minWidth: "8vw", textAlign: "center" }}
                  >
                    {[
                      { name: this.props.t("last-3-days"), value: 259200000 },
                      { name: this.props.t("last-week"), value: 604800000 },
                      { name: this.props.t("last-2-weeks"), value: 1209600000 },
                      { name: this.props.t("last-month"), value: 2629743000 },
                      {
                        name: this.props.t("last-3-months"),
                        value: 7889229000,
                      },
                      {
                        name: this.props.t("last-6-months"),
                        value: 15778458000,
                      },
                      { name: this.props.t("last-year"), value: 31556926000 },
                    ].map((date: any) => (
                      <MenuItem key={date.value} value={date.value}>
                        {date.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <InputLabel id="per">
                    {this.props.t("time-interval")}
                  </InputLabel>
                  <Select
                    labelId="per"
                    value={this.state.per}
                    onChange={async (e: any) => {
                      await this.setState({
                        per: e.target.value,
                      });
                      this.syncData();
                    }}
                  >
                    {[
                      { name: this.props.t("daily"), value: 86400000 },
                      { name: this.props.t("3-daily"), value: 259200000 },
                      { name: this.props.t("weekly"), value: 604800000 },
                      { name: this.props.t("monthly"), value: 2629743000 },
                      { name: this.props.t("3-monthly"), value: 7889229000 },
                      { name: this.props.t("6-monthly"), value: 15778458000 },
                    ].map((p: any) => (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            ) : (
              <>
                <Grid container justifyContent="left">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
                      <DateTimePicker
                        label={this.props.t("start-date")!}
                        inputFormat="DD/MM/YYYY HH:mm"
                        value={this.state.startdate}
                        onChange={async (value: any) => {
                          await this.setState({
                            startdate: value,
                          });
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                      <DateTimePicker
                        label={this.props.t("end-date")!}
                        inputFormat="DD/MM/YYYY HH:mm"
                        value={this.state.enddate}
                        onChange={async (value: any) => {
                          await this.setState({
                            enddate: value,
                          });
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid container justifyContent="right">
                  <ButtonGroup orientation="vertical">
                    <ThemeProvider theme={theme}>
                      <SubbranchButton
                        sx={{
                          border: "1px solid black",
                          "&:hover": {
                            color: theme.default.primary,
                            backgroundColor: theme.default.secondary,
                          },
                        }}
                        variant="contained"
                        onClick={() => this.setState({ branchDialog: true })}
                      >
                        {this.props.t("selectedBranchs")}(
                        {this.state.selectedBranches.length})
                      </SubbranchButton>
                    </ThemeProvider>
                    <Button
                      sx={{ border: "1px solid black" }}
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        this.syncData();
                      }}
                    >
                      {this.props.t("get-reports")}
                    </Button>
                    <Button
                      startIcon={<FileDownloadIcon />}
                      sx={{
                        border: "1px solid black",
                        backgroundColor: "#717D8C",
                      }}
                      variant="contained"
                      onClick={() => this.exportReport()}
                    >
                      {this.props.t("export-reports")}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </>
            )}
          </Stack>
        </Box>

        <Box className="charts" sx={{ marginTop: "2vw" }}>
          {this.state.chartType == this.props.t("table-chart") ? (
            <Box>
              {this.state.reportData?.length == 0 ? (
                <>
                  <NoReport />
                </>
              ) : (
                <>
                  {this.state.reportData
                    ?.filter((report: any) =>
                      this.state.selectedBranches.includes(report.branchId)
                    )
                    .map((report: any) => (
                      <this.TableReport {...report} />
                    ))}
                </>
              )}
            </Box>
          ) : this.state.chartType == this.props.t("line-chart") ? (
            <Box sx={{ height: "100%", marginLeft: "3.5vw", marginTop: "4vw" }}>
              {/* <Box sx={{ marginBottom: "2vw" }}>
                <LineChart
                  width={1100}
                  height={600}
                  data={this.state.lineData.reports}
                >
                  <Line
                    type="monotone"
                    strokeWidth={2}
                    legendType="circle"
                    dataKey="saleCount"
                    name={this.props.t("sale-count")!}
                    stroke="#00911A"
                  />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="from" />
                  <YAxis interval={0} tick={{ strokeWidth: 2 }} />
                  <Tooltip />
                  <Legend />
                </LineChart>
              </Box>
              <Box>
                <LineChart
                  width={1100}
                  height={600}
                  data={this.state.lineData.reports}
                >
                  <Line
                    type="monotone"
                    strokeWidth={2}
                    legendType="circle"
                    dataKey="totalPrice"
                    name={this.props.t("total-price")!}
                    stroke="#009BBA"
                  />
                  <Line
                    type="monotone"
                    strokeWidth={2}
                    legendType="circle"
                    dataKey="averagePrice"
                    name={this.props.t("average-price")!}
                    stroke="#3811F7"
                  />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="from" />
                  <YAxis interval={0} tick={{ strokeWidth: 2 }} />
                  <Tooltip cursor={false} />
                  <Legend />
                </LineChart>
              </Box> */}
            </Box>
          ) : this.state.chartType == this.props.t("pie-chart") ? (
            <Box>
              {this.state.reportData?.length == 0 ? (
                <>
                  <NoReport />
                </>
              ) : (
                <>
                  {/* {this.state.reportData
                    ?.filter((report: any) =>
                      this.state.selectedBranches.includes(report.branchId)
                    )
                    .map((report: any) => (
                      <this.PieReport {...report} />
                    ))} */}
                </>
              )}
            </Box>
          ) : this.state.chartType == this.props.t("bar-chart") ? (
            <Box>
              {this.state.reportData?.length == 0 ? (
                <>
                  <NoReport />
                </>
              ) : (
                <>
                  {/* {this.state.reportData
                    ?.filter((report: any) =>
                      this.state.selectedBranches.includes(report.branchId)
                    )
                    .map((report: any) => (
                      <this.BarReport {...report} />
                    ))} */}
                </>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
}

export default withTranslation()(Product);
